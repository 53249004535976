import { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

const useIsInView = (startThreshold: number, stopThreshold: number) => {
  const [shouldPlay, setShouldPlay] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const isPartiallyVisible =
        rect.bottom >= windowHeight * startThreshold &&
        rect.top <= windowHeight * stopThreshold;

      setShouldPlay(isPartiallyVisible);
    }
  };

  useEffectOnce(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { shouldPlay, targetRef };
};

export default useIsInView;
